import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'

import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

const news = () => {
    return (
        <Layout pageTitle="PragICTS | Cyber Security Operations (CyberSecOps) | News">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                            <h2>News</h2>
                   
                                <div className="horizonral-subtitle"><span>News</span></div>
                            </div>
            
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="solutions">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" >NEWS <span>//</span></div>
            <div className="container">
               
                <div className="custom-inner-holder  mar-bottom">
            
                 
                    
                    <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Unicorn Metalics | 12th August 2021</h4>
                                    <p>PragICTS secures the Unicorn Metalics ( https://www.unicornmetalics.com/ ) Internet access through the implementation of Fortinet Firewall Appliance ( https://www.fortinet.com/ ).</p>
                                    <a href="https://www.unicornmetalics.com/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                          
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Asian Group of Companies| 04th August 2021</h4>
                                    <p>PragICTS implements Egnyte's cloud storage solution for cloud-only storage configuration with no local storage option for the Asian Group of Companies ( https://agc.lk/ ).</p>
                                    <a href="https://agc.lk/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Asian Group of Companies | 13th July 2021</h4>
                                    <p>PragICTS migrate a few specific Google Workspace ( https://workspace.google.com/ ) email facilities to Microsoft Office 365 ( https://www.office.com/ ) for greater cost optimization for the Asian Group of Companies ( https://agc.lk/ ).</p>
                                    <a href="https://agc.lk/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Asian Group of Companies | 14th July 2021</h4>
                                    <p>Asian Group of Companies | 14th July 2021PragICTS is recognized as an MSP (Managed Service Provider) of Egnyte US ( https://www.egnyte.com/ ) for their cloud services portfolio.</p>
                                    <a href="https://www.egnyte.com/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Authenticities Sri Lanka | 14th July 2021</h4>
                                    <p>Authenticities Sri Lanka | 14th July 2021PragICTS is recognized as an MSP (Managed Service Provider) of Egnyte US ( https://www.egnyte.com/ ) for their cloud services portfolio.</p>
                                    <a href="https://authencitiessrilanka.com/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Partnership | 02nd April 2021</h4>
                                    <p>PragICTS is recognized as an MSP (Managed Service Provider) of Fortinet US ( https://www.fortinet.com/ ) for their security/cloud products and solutions portfolio.</p>
                                    <a href="https://www.fortinet.com/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>ToolsHub | 02nd December 2020</h4>
                                    <p>PragICTS implements Google Workspace ( https://workspace.google.com/ ) as the primary corporate email facility with personalization for Tools Hub ( https://www.toolshub.lk/ ) .</p>
                                    <a href="https://toolshub.lk/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Authenticities Sri Lanka | 02nd December 2020</h4>
                                    <p>PragICTS implements NAS (Network Attached Storage) solution for Authenticities Sri Lanka ( https://authencitiessrilanka.com/ ) deploying NAS solutions by Synology ( https://www.synology.com/en-global ) for centralized data management.</p>
                                    <a href="https://authencitiessrilanka.com/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Asian Group of Companies | 11th September 2018</h4>
                                    <p>PragICTS implements NAS (Network Attached Storage) solution for Authenticities Sri Lanka ( https://authencitiessrilanka.com/ ) deploying NAS solutions by Synology ( https://www.synology.com/en-global ) for centralized data management.</p>
                                    <a href="https://unicornmetalics.com/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Asian Group of Companies | 17th October 2017</h4>
                                    <p>PragICTS implements NAS (Network Attached Storage) solution for Asian Group of Companies ( https://agc.lk/ ) deploying NAS solutions by Synology ( https://www.synology.com/en-global ) for centralized data management.</p>
                                    <a href="https://agc.lk/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Asian Group of Companies | 16th June 2016</h4>
                                    <p>PragICTS implements IT asset tracking and management platform Snipe-IT ( https://snipeitapp.com/ ) for Asian Group of Companies ( https://agc.lk/ ) for effective tracking, management, and performance of the IT function by PragICTS.</p>
                                    <a href="https://agc.lk/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Asian Group of Companies | 3rd February 2016</h4>
                                    <p>PragICTS signs an agreement with the Asian Group of Companies ( https://agc.lk/ ) for the setup, design, and management of its total IT infrastructure.</p>
                                    <a href="https://agc.lk/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Asian Group of Companies | 16th May 2015</h4>
                                    <p>PragICTS implements Google Workspace ( https://workspace.google.com/ ) as the primary corporate email facility with personalization for Asian Group of Companies ( https://agc.lk/ ).</p>
                                    <a href="https://agc.lk/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Authenticities Sri Lanka| 16th June 2016</h4>
                                    <p>PragICTS signs agreement with Authenticities Sri Lanka ( https://authencitiessrilanka.com/ ) for the setup, design, and management of its total IT infrastructure.</p>
                                    <a href="https://authencitiessrilanka.com/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Authenticities Sri Lanka | 17th October 2017</h4>
                                    <p>PragICTS secures the Authenticities Sri Lanka ( https://authencitiessrilanka.com/ ) Internet access through the implementation of Fortinet Firewall Appliance ( https://www.fortinet.com/ ).</p>
                                    <a href="https://authencitiessrilanka.com/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Authenticities Sri Lanka | 1st April 2013</h4>
                                    <p>PragICTS implements Google Workspace ( https://workspace.google.com/ ) as the primary corporate email facility with personalization for Authenticities Sri Lanka ( https://authencitiessrilanka.com/ ).</p>
                                    <a href="https://authencitiessrilanka.com/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
                 

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default news
